import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';

import rejectionService from '@services/rejection';

import QuoteStep from '@templates/QuoteStep';
import Header from '@organisms/header/Header';
import StepTitle from '@atoms/titles/pageTitles/StepTitle';
import { MainContainer, ContentContainer, Content, InnerContent } from '@templates/Main';

import RejectionFooter from '@molecules/asides/RejectionFooter.container';
import NewDriverCrossSellModal from '@organisms/modals/NewDriverCrossSellModal';
import { InformationBox, PillButton } from '@rentecarlo/component-library';

import { AlternativeProducts } from '@redux/reducer/quote/alternativeProducts';
import { RejectionReasons } from '@redux/reducer/quote/saveTypes';
import { ProductType } from '@utils/types';
import { useOptimizelyTrack } from '@hooks';
import { Page } from '../../../ui-tools';

import NewDriverCard from './NewDriverCard';
import useResetQuote from './useResetQuote';

const Image = styled.img`
  display: block;
  margin: 0 auto;
  margin-bottom: 16px;
`;

const Text = styled.p`
  font-family: ${({ theme }) => theme.title};
  font-size: 18px;
  line-height: 23px;
  text-align: left;
  font-weight: bold;

  color: ${({ theme }) => theme.textPrimary};
  margin-bottom: 16px;
`;

const ImportantBoxContainer = styled.div`
  margin-bottom: 16px;
`;

const IpidContainer = styled.div`
  display: flex;
  justify-content: center;

  margin-top: 32px;
`;

interface Props {
  restrictedPeriod: boolean;
  rejectionReasons: RejectionReasons[];
  fetchRestrictedPeriods: () => void;
  location: { pathname: string };
  product: ProductType;
  isSubscription: boolean;
  alternativeProducts: AlternativeProducts;
  updateQuoteToNewDriver: () => void;
}

const Rejection = ({
  restrictedPeriod,
  rejectionReasons,
  fetchRestrictedPeriods,
  location,
  alternativeProducts,
  updateQuoteToNewDriver,
}: Props): JSX.Element => {
  // Stateful page rejection reasons so we can reset the quote
  // while keeping the page relevant to the rejection that led us here
  const [pageRejectionReasons, setPageRejectionReasons] = useState<RejectionReasons[]>();
  const hasRejectionReasons = useRef(false);
  const [newDriverCrossSellModalIsOpen, setNewDriverCrossSellModalIsOpen] = useState(false);
  const sendEvent = useOptimizelyTrack();
  const newDriverCrossSellResults = alternativeProducts.get('newdriver');

  const [redirectToStartHandler] = useResetQuote();

  useEffect(() => {
    if (hasRejectionReasons.current) return;
    if (!rejectionReasons) {
      // Customer may have refreshed after landing on the page.
      // Their quote and rejection reasons will have reset at this point.
      // Redirect them to the start if they have no reasons to show.
      redirectToStartHandler();
      return;
    }
    fetchRestrictedPeriods();
    setPageRejectionReasons(rejectionReasons);
    hasRejectionReasons.current = true;
  }, [rejectionReasons, redirectToStartHandler, fetchRestrictedPeriods]);

  useEffect(() => {
    if (
      newDriverCrossSellResults?.price &&
      pageRejectionReasons &&
      rejectionService.getEligibleForCrossSell(pageRejectionReasons)
    ) {
      sendEvent('new_driver_rejection_cross_sell_price');
    } else {
      sendEvent('rejection_page_view');
    }
  }, [newDriverCrossSellResults, pageRejectionReasons, sendEvent]);

  const closeNewDriverCrossSellModal = () => {
    setNewDriverCrossSellModalIsOpen(false);
  };

  const newDriverModalAction = () => {
    sendEvent('new_driver_rejection_cross_sell_continue');
    updateQuoteToNewDriver();
  };

  return (
    <MainContainer id='rejection-container'>
      {newDriverCrossSellResults?.price && (
        <NewDriverCrossSellModal
          show={newDriverCrossSellModalIsOpen}
          onClose={closeNewDriverCrossSellModal}
          action={newDriverModalAction}
        />
      )}
      <Header pathname={location.pathname} />
      <ContentContainer>
        <Content>
          <InnerContent id='rejection-container-innerContent'>
            {newDriverCrossSellResults?.price &&
            rejectionService.getEligibleForCrossSell(pageRejectionReasons) ? (
              <QuoteStep
                stepTitle="Sorry, we can't offer you this insurance"
                stepSubtitle="Unfortunately, we're unable to provide the insurance you've quoted for on this occasion. However, you are eligible for our New Driver insurance subscription. See below for more details."
                titleMarginBottom='16px'
              >
                <ImportantBoxContainer>
                  <InformationBox id='rejection-text-information' type='important'>
                    Please note that our Customer Care agents are not able to change this decision
                    or provide specific reasoning.
                  </InformationBox>
                </ImportantBoxContainer>
                <Text id='rejection-text-eligibility'>
                  However you are eligible for our New driver insurance subscription:
                </Text>
                <NewDriverCard
                  setNewDriverCrossSellModalIsOpen={setNewDriverCrossSellModalIsOpen}
                  newDriverCrossSellResults={newDriverCrossSellResults}
                />
                <IpidContainer>
                  <PillButton
                    id='rejection-button-IPIDPill'
                    onClick={() => {
                      window.open(
                        `${process.env.REACT_APP_ASSETS_URL}/docs/newdriver/New_Driver_Insurance_Product_Information_Document.pdf`,
                        '_blank',
                      );
                    }}
                  >
                    Read our Product Information Guide
                  </PillButton>
                </IpidContainer>
              </QuoteStep>
            ) : (
              <>
                <StepTitle title="Sorry, we can't offer you insurance" paddingTop='50px' />
                <Image src={rejectionService.getImage(pageRejectionReasons)} />
                <Page id='rejection-component-page' borderTop={false}>
                  {rejectionService.getSubtitle(pageRejectionReasons, restrictedPeriod)}
                </Page>
                <ImportantBoxContainer>
                  <InformationBox id='rejection-text-finalDecision' type='important'>
                    Please note that our Customer Care agents are not able to change this decision
                    or provide specific reasoning.
                  </InformationBox>
                </ImportantBoxContainer>
                <RejectionFooter />
              </>
            )}
          </InnerContent>
        </Content>
      </ContentContainer>
    </MainContainer>
  );
};

export default Rejection;
