import React from 'react';

import { Field, reduxForm, InjectedFormProps } from 'redux-form';
import { useSelector, useDispatch } from 'react-redux';

import { useAppSelector, useOptimizelyTrack } from '@hooks';
import { saveAndGoTo } from '@reducer/quote/save.actions';
import { RootState } from '@redux/reducer';
import camelToSnakeCase from '@services/camelToSnakeCase';
import validation from '@services/formValidation';

import { setPaymentMethod } from '@reducer/quote/product.actions';

import QuoteStep from '@templates/QuoteStep';
import PaymentMethodSelector from '@molecules/PaymentMethodSelector';
import { PaymentMethod } from '@utils/types';

import PriceTagBox from '@atoms/blocks/PriceTagBox';
import { PriceTagContainer } from './assets/styles';

const ChooseYourCover: React.FC<InjectedFormProps> = ({ handleSubmit, valid }) => {
  const sendEvent = useOptimizelyTrack();

  const dispatch = useDispatch();
  const product = useSelector((state: RootState) => state.quote.product);
  const isQuoteForMyself = useAppSelector((state) => state.account.customer.isQuoteForMyself);

  return (
    <QuoteStep
      id='chooseYourCover-component-quoteStep'
      stepTitle={isQuoteForMyself ? 'Choose your cover...' : 'Choose their cover...'}
      showButton
      nextDisabled={!valid}
      nextLabel='Continue'
      nextAction={handleSubmit((values: Record<string, string>) => {
        const updatedPaymentMethod = values.paymentMethod as PaymentMethod;
        if (product.paymentMethod !== updatedPaymentMethod) {
          sendEvent(`choose_your_cover_${camelToSnakeCase(values.paymentMethod)}`);
          dispatch(setPaymentMethod(updatedPaymentMethod));
        }
        dispatch(saveAndGoTo('indicative-price'));
      })}
    >
      <Field
        id='chooseYourCover-field-paymentMethod'
        name='paymentMethod'
        component={PaymentMethodSelector}
        validate={[validation.isRequired]}
      />
      {product.productType === 'ldp' && (
        <PriceTagContainer>
          <PriceTagBox id='chooseYourCover-text-learnerDiscountMessaging' alignIcon='self-start'>
            When you pass and upgrade to a full licence, as a Veygo learner, you get a 10% discount
            on any policies you buy over the next year. T&Cs apply.
          </PriceTagBox>
        </PriceTagContainer>
      )}
    </QuoteStep>
  );
};

export default reduxForm({
  form: 'chooseYourCoverForm',
})(ChooseYourCover);
