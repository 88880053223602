/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable indent */
import React, { useState, useCallback, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Field, FormSection, WrappedFieldInputProps, WrappedFieldMetaProps } from 'redux-form';

import { Dayjs } from 'dayjs';

import {
  FieldContainer,
  Tooltip,
  TooltipEvents,
  InformationBox,
  LinkButton,
  Card,
  CardUtils,
  PillButton,
} from '@rentecarlo/component-library';
import { useOptimizelyFlag, useOptimizelyTrack } from '@hooks';
import Plus from '@assets/plus.svg';
import AccountIcon from '@assets/account-icon-active.svg';
import Group from '@atoms/layout/form/Group';
import SectionSubTitle from '@atoms/titles/componentTitles/SectionSubTitle';
import SectionTitle from '@atoms/titles/componentTitles/SectionTitle';
import DrivingLicenceInput from '@atoms/inputs/DrivingLicenceInput';
import MultipleChoiceField from '@molecules/inputs/MultipleChoiceField';
import ToggleButtons from '@molecules/inputs/ToggleButtonsField';
import NCBForm from '@organisms/forms/NCBForm';

import ReduxFieldParsers from '@services/formParsers';
import ReduxFieldValidation from '@services/formValidation';
import QuoteStep from '@templates/QuoteStep';
import size from '@config/size';

import claimsOptions from '@config/claimsOptions';
import colors from '@config/colors';

import DLNIconLarge from '@assets/licence-icon.svg';
import provisionalDLN from '@assets/provisional-dln.png';
import fullDLN from '@assets/full-dln.png';

interface ToggleButtonProps {
  input: WrappedFieldInputProps;
  meta: WrappedFieldMetaProps;
  name: string;
  displayLeftError: boolean;
  id: string;
  hasValidationError: boolean;
  validationErrorMessage: string;
}

const ToggleButtonField = ({
  input,
  meta,
  name,
  displayLeftError,
  id,
  hasValidationError,
  validationErrorMessage,
}: ToggleButtonProps) => (
  <ToggleButtons
    value={input.value}
    onSelect={input.onChange}
    leftLabel='YES'
    rightLabel='NO'
    error={meta.error}
    errorMessage=''
    groupName={name}
    displayLeftError={displayLeftError}
    id={id}
    hasValidationError={hasValidationError}
    validationErrorMessage={validationErrorMessage}
    page='drivingHistory'
  />
);

const StyledBox = styled.div`
  margin-top: 32px;
  border-radius: 4px;
  background-color: ${colors.grey.background.light};
  padding: 30px 24px;
`;

const BoxTitleContainer = styled.div`
  margin-right: 64px;
`;

const DLNContainer = styled.div`
  margin: 64px 0px 10px 0;
`;

const DLNInfoContainer = styled.div`
  display: flex;
  background-color: ${colors.grey.background.light};
  border-radius: 8px;
  padding: 16px;
`;

const DLNIconContainer = styled.img`
  margin-right: 16px;
`;

const DLNTextContainer = styled.div`
  font-family: proxima-soft;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: ${colors.outerSpace};
`;

const DLNInputContainer = styled.div`
  display: flex;
  flex-direction: row;
  box-shadow: 0 1px 3px 0 ${colors.shadow};
  margin-bottom: 16px;
`;

const DLNSubTitle = styled.div`
  @media (min-width: ${size.desktopWidth}) {
    width: 360px;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const DLNImage = styled.img`
  height: 140px;
  width: 250px;
  margin-left: -15px;
`;

const TooltipText = styled.div`
  color: ${colors.outerSpace};
  font-family: proxima-soft;
  font-size: 14px;
  margin-top: 8px;
  line-height: 16px;
`;

const IconContainer = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  width: 16px;
  height: 16px;
  margin-right: 7px;
`;

const TooltipLink = styled.span`
  cursor: pointer;
  font-family: proxima-soft;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  color: ${colors.blue.royal};
`;

const questionText = (productType: string, isQuoteForMyself: boolean) => {
  if (productType === 'tc') {
    return `Have ${
      isQuoteForMyself ? 'you' : 'they'
    } had any accidents, losses, thefts, incidents or claims in the last 3 years (regardless of fault)`;
  }
  return `Have ${
    isQuoteForMyself ? 'you' : 'they'
  } (the driver) had any accidents, losses, thefts, incidents or claims in the last 3 years (regardless of fault)`;
};

export interface NCBFields {
  haveNCB: string;
  level: string;
}

interface DrivingHistoryInitialFields {
  licenceNumberFinalPart: string;
}

export interface DrivingHistoryFields extends DrivingHistoryInitialFields {
  hasClaims: string;
  numberOfClaims: string;
  ncb: NCBFields;
  fullLicenceConfirmed: boolean;
  fullLicenceDate: Dayjs;
  licenceNumberSurnamePart: string;
  licenceNumberDOBPart: string;
}

export interface DrivingHistoryProps {
  handleSubmit: () => void;
  initialValues: DrivingHistoryInitialFields;
  licenceType: string;
  productType: string;
  hasClaims: boolean | null;
  haveNCB: boolean | null;
  customerNCB: boolean | null;
  level: number | null;
  setNCBLevel: () => void;
  licenceNumber: string;
  prefilled: boolean;
  submitFailed: boolean;
  formErrors: Record<string, unknown>;
  form: string;
  valid: boolean;
  goToLogin: () => void;
  goToForgottenPassword: () => void;
  birthdate: Dayjs | null;
  isLicenceNotFound: boolean;
  isLicenceExpired: boolean | null;
  dlnAlreadyUsed: boolean;
  isLoggedIn: boolean;
  saveError: string | null;
  sendGaErrorEvent: (label: string) => void;
  userIdentity: string;
  isSubscription: boolean;
  isQuoteForMyself: boolean;
}

const DrivingHistory: React.FC<DrivingHistoryProps> = (props) => {
  const [error, setError] = useState({
    licenceNumberSurnamePart: null,
    licenceNumberDOBPart: null,
    licenceNumberFinalPart: null,
  });

  const tooltipEvents = new TooltipEvents();

  const updateError = useCallback((field: string, errorMessage: string) => {
    setError((previous) => ({
      ...previous,
      [field]: errorMessage,
    }));
  }, []);

  const shouldShowDLNError = () => {
    const { isLicenceNotFound, isLicenceExpired, dlnAlreadyUsed } = props;
    return !!(
      error.licenceNumberSurnamePart ||
      error.licenceNumberFinalPart ||
      error.licenceNumberDOBPart ||
      dlnAlreadyUsed ||
      isLicenceNotFound ||
      isLicenceExpired
    );
  };

  const errorToShow = (
    licenceNumber: string,
    goToLogin: () => void,
    goToForgottenPassword: () => void,
  ) => {
    const { isLicenceNotFound, isLicenceExpired, dlnAlreadyUsed } = props;
    let errorMessageToShow: JSX.Element | string = '';

    if (
      licenceNumber.length < 16 &&
      (error.licenceNumberSurnamePart || error.licenceNumberFinalPart || error.licenceNumberDOBPart)
    ) {
      errorMessageToShow = 'Must be exactly 16 characters';
    } else if (error.licenceNumberSurnamePart && licenceNumber.length === 16) {
      errorMessageToShow = error.licenceNumberSurnamePart;
    } else if (error.licenceNumberFinalPart && licenceNumber.length === 16) {
      errorMessageToShow = error.licenceNumberFinalPart;
    } else if (error.licenceNumberDOBPart && licenceNumber.length === 16) {
      errorMessageToShow = error.licenceNumberDOBPart;
    } else if (isLicenceExpired) {
      errorMessageToShow =
        'It looks like your licence has expired. Please check you are entering the correct details before you try again.';
    } else if (isLicenceNotFound) {
      errorMessageToShow =
        'The Driving Licence Number you have provided is not valid. Please check that you have entered the 16 characters from your Driving Licence Number correctly.';
    } else if (dlnAlreadyUsed) {
      errorMessageToShow = (
        <div>
          <p id='drivingHistory-paragraph-errorMessageLinks'>
            The details you have provided are already linked to an account. Please click here to{' '}
            <LinkButton id='drivingHistory-button-goToLogin' action={goToLogin}>
              log in
            </LinkButton>
            . If you have forgotten your password, please follow this{' '}
            <LinkButton
              id='drivingHistory-button-goToForgottenPassword'
              action={goToForgottenPassword}
            >
              link
            </LinkButton>{' '}
            to receive a reset password email.
          </p>
          <p id='drivingHistory-paragraph-errorCheck'>
            If you can't see it within a few minutes, please check your junk / spam / promotions
            folder to see if it's hiding!
          </p>
        </div>
      );
    }

    return errorMessageToShow;
  };

  const {
    handleSubmit,
    productType,
    hasClaims,
    haveNCB,
    licenceNumber,
    prefilled,
    submitFailed,
    formErrors,
    form,
    valid,
    goToLogin,
    goToForgottenPassword,
    saveError,
    sendGaErrorEvent,
    isQuoteForMyself,
  } = props;

  const [serviceErrorCounter, setServiceErrorCounter] = useState(0);
  const [additionalDriverSelected, setAdditionalDriverSelected] = useState(false);
  const showAltServiceError = serviceErrorCounter < 2;

  const initialRender = useRef(true);

  const showAdditionalDriver = useOptimizelyFlag('TEMP_ADDITIONAL_DRIVER_FAKE_DOOR').enabled;
  const sendEvent = useOptimizelyTrack();

  useEffect(() => {
    if (saveError && !initialRender.current) {
      setServiceErrorCounter((counter) => (saveError === 'SERVICEERROR' ? counter + 1 : 0));
    }
    initialRender.current = false;
  }, [saveError]);

  useEffect(() => {
    if (serviceErrorCounter) {
      sendGaErrorEvent(serviceErrorCounter > 1 ? 'SECOND_ERROR' : 'FIRST_ERROR');
    }
  }, [serviceErrorCounter, sendGaErrorEvent]);

  const handleAdditionalDriverClick = () => {
    setAdditionalDriverSelected(true);
    sendEvent('additional_driver_selected');
  };

  return (
    <form onSubmit={handleSubmit}>
      <QuoteStep
        id='drivingHistory-component-quoteStep'
        nextAction={handleSubmit}
        stepTitle='Driving history'
        stepSubtitle='Please answer all of the questions to the best of your knowledge. Failure to do so could affect any claims made.'
        formErrors={formErrors}
        form={form}
        submitError={submitFailed && !valid}
      >
        <Group>
          <SectionTitle id='drivingHistory-title'>Accidents and claims</SectionTitle>
          <SectionSubTitle id='drivingHistory-subtitle'>
            {questionText(productType, isQuoteForMyself)}
          </SectionSubTitle>
          <Field
            props={{ name: 'hasClaims' }}
            name='hasClaims'
            component={ToggleButtonField}
            validate={[ReduxFieldValidation.required]}
            id='drivingHistory-field-hasClaims'
            hasValidationError={submitFailed && formErrors && formErrors.hasClaims}
            validationErrorMessage='Accidents and claims is required'
          />
          <InformationBox id='drivingHistory-text-importantInformation' type='important'>
            {`This includes incidents that have occurred whilst ${
              isQuoteForMyself ? 'you' : 'they'
            } have been driving any vehicle. This also includes any incidents that have occurred in a vehicle insured under ${
              isQuoteForMyself ? 'your' : 'their'
            } name, even if you were not the driver at the time.`}
          </InformationBox>
          {hasClaims && (
            <StyledBox>
              <BoxTitleContainer>
                <SectionTitle id='drivingHistory-title-claims'>
                  {`How many accidents or claims have ${isQuoteForMyself ? 'you' : 'they'} had?`}
                </SectionTitle>
              </BoxTitleContainer>
              <Field
                id='drivingHistory-field-numberOfClaims'
                name='numberOfClaims'
                component={MultipleChoiceField}
                options={claimsOptions}
                validate={[ReduxFieldValidation.isRequired]}
              />
            </StyledBox>
          )}
        </Group>
        {productType !== 'ldp' && productType !== 'newdriver' && (
          <FormSection name='ncb'>
            <NCBForm
              haveNCB={haveNCB}
              productType={productType}
              formErrors={formErrors}
              submitFailed={submitFailed}
              isQuoteForMyself={isQuoteForMyself}
            />
          </FormSection>
        )}
        <Group>
          <DLNContainer>
            <TitleContainer>
              <SectionTitle id='drivingHistory-title-DLN' hideMargin>
                {`Enter ${isQuoteForMyself ? 'your' : 'their'} 16 digit GB driving licence number`}
              </SectionTitle>
              <IconContainer>
                <Tooltip
                  title='Help'
                  modalTitle={`Enter ${
                    isQuoteForMyself ? 'your' : 'their'
                  } 16 digit GB driving licence number`}
                  tooltipDirection='right'
                  events={tooltipEvents}
                >
                  <DLNImage src={productType === 'ldp' ? provisionalDLN : fullDLN} />
                  <TooltipText id='drivingHistory-text-DLNToolTip'>
                    We only need the details shown in the red box.
                  </TooltipText>
                </Tooltip>
              </IconContainer>
            </TitleContainer>
            <DLNSubTitle>
              <SectionSubTitle id='drivingHistory-subtitle-DLN'>
                We’ve pre-filled the first 11 characters, the last 5 are up to you. Don’t worry, if
                we’ve made a mistake you can edit it.
              </SectionSubTitle>
            </DLNSubTitle>
            <FieldContainer
              id='drivingHistory-container-licenceNumberFieldContainer'
              showErrorContainer={submitFailed && shouldShowDLNError()}
              showError={shouldShowDLNError()}
              error={errorToShow(licenceNumber, goToLogin, goToForgottenPassword)}
            >
              <DLNInputContainer>
                <Field
                  name='licenceNumberSurnamePart'
                  component={DrivingLicenceInput}
                  borderRadius='4px 0 0 4px'
                  parse={ReduxFieldParsers.toUpperCase}
                  validate={[ReduxFieldValidation.validDLNSurnamePart]}
                  disabled={!!(licenceNumber && prefilled)}
                  id='drivingHistory-field-licenceNumberSurnamePart'
                  maxLength={5}
                  setError={updateError}
                />
                <Field
                  name='licenceNumberDOBPart'
                  component={DrivingLicenceInput}
                  borderRadius='0'
                  border={false}
                  parse={ReduxFieldParsers.toUpperCase}
                  validate={[
                    ReduxFieldValidation.validDLNDOBPart,
                    ReduxFieldValidation.dobMatchDln,
                  ]}
                  disabled={!!(licenceNumber && prefilled)}
                  id='drivingHistory-field-licenceNumberDOBPart'
                  maxLength={6}
                  setError={updateError}
                />
                <Field
                  name='licenceNumberFinalPart'
                  component={DrivingLicenceInput}
                  textAlign='center'
                  borderRadius='0 4px 4px 0'
                  parse={ReduxFieldParsers.toUpperCase}
                  validate={[ReduxFieldValidation.validDLNFinalPart]}
                  disabled={!!(licenceNumber && prefilled)}
                  id='drivingHistory-field-licenceNumberFinalPart'
                  maxLength={5}
                  setError={updateError}
                />
              </DLNInputContainer>
            </FieldContainer>
          </DLNContainer>
          <DLNInfoContainer>
            <DLNIconContainer src={DLNIconLarge} />
            <DLNTextContainer id='drivingHistory-container-DLN'>
              {`We need ${isQuoteForMyself ? 'your' : 'their'} driving licence to verify ${
                isQuoteForMyself ? 'your' : 'their'
              } identity and driving history.`}
              <TooltipLink
                id='drivingHistory-link-whereIsMyLicenceNumber'
                onMouseEnter={() => tooltipEvents.onHover()}
                onMouseLeave={() => tooltipEvents.onHover()}
                onClick={() => tooltipEvents.onPin()}
              >
                Where is my licence number?
              </TooltipLink>
            </DLNTextContainer>
          </DLNInfoContainer>
        </Group>
        {saveError === 'SERVICEERROR' && (
          <Group>
            <InformationBox
              id='drivingHistory-text-altServiceError'
              type='error'
              subheading={showAltServiceError ? 'OOPS!' : 'Still no luck?:'}
            >
              {showAltServiceError
                ? 'Something went wrong our end. Please try again'
                : 'Hmmm, seems to have happened again. Could you wait a minute and try once more please'}
            </InformationBox>
          </Group>
        )}
        {showAdditionalDriver && (
          <Group id='drivingHistory-additionalDriver'>
            {!additionalDriverSelected ? (
              <Card>
                <CardUtils.CardWrapper>
                  <CardUtils.StyledCard>
                    <CardUtils.Wrapper>
                      <CardUtils.CardTitleWrapper>
                        <CardUtils.CardTitleIcon icon={AccountIcon} />
                        <CardUtils.CardTitleTitle
                          id='drivingHistory-additionalDriver-addDriverCard'
                          fontSize={18}
                        >
                          Add a driver
                        </CardUtils.CardTitleTitle>
                      </CardUtils.CardTitleWrapper>
                    </CardUtils.Wrapper>

                    <CardUtils.CardSubtitleWrapper id='drivingHistory-additionalDriver-subtitle'>
                      Sharing the vehicle with a friend or family member? Add up to four additional
                      drivers to your policy.
                    </CardUtils.CardSubtitleWrapper>

                    <CardUtils.ChildrenWrapper>
                      <CardUtils.LeftMarginWrapper>
                        <PillButton
                          id='drivingHistory-additionalDriver-button'
                          data-testid='drivingHistory-addDriver-button'
                          leftImage={Plus}
                          onClick={handleAdditionalDriverClick}
                        >
                          Add a driver
                        </PillButton>
                      </CardUtils.LeftMarginWrapper>
                    </CardUtils.ChildrenWrapper>
                  </CardUtils.StyledCard>
                </CardUtils.CardWrapper>
              </Card>
            ) : (
              <InformationBox type='warning' id='drivingHistory-additionalDriver-information'>
                Thanks for your interest in adding a driver! This feature isn’t available just yet,
                but we’re working on it. Stay tuned.
              </InformationBox>
            )}
          </Group>
        )}
      </QuoteStep>
    </form>
  );
};

DrivingHistory.defaultProps = {
  customerNCB: null,
  hasClaims: null,
  haveNCB: null,
  level: null,
};

export default DrivingHistory;
