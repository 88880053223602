/**
 * Quote API implemented with RTK Query.
 *
 * { @link https://redux-toolkit.js.org/rtk-query/overview | RTK Query Overview }
 * */

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { getCookie, retrieveCognitoBearerToken } from './httpClient';

import { quotes as quotesTransformer } from '../transformers';

interface CheckoutResponse {
  driver_email: string;
  driver_first_name: string;
  driver_last_name: string;
  is_first_purchase: boolean;
  is_subscription: boolean;
  price: string;
  product_type: string;
  reference_number: string;
  utm_source: string;
  commission_group: string;
  duration_start: string;
  duration_end: string;
  subscription_reference_number: string;
  number_of_policies: number;
}

interface NewQuote {
  uuid: string;
  reference_number: string;
  car_abi_code: string;
  car_engine_size: number;
  car_has_insurance: boolean;
  car_main_insurer: string;
  car_make: string;
  car_model: string;
  car_registration_number: string;
  car_transmission: string;
  car_value: number;
  car_year: number;
  car_terms_of_cover_confirmed?: boolean;
  cc_claims: boolean;
  cc_number_of_claims: number;
  driver_address_county: string;
  driver_address_country: string;
  driver_address_flat_no: string;
  driver_address_house_name: string;
  driver_address_house_no: string;
  driver_address_is_manual: boolean;
  driver_address_road: string;
  driver_address_line1: string;
  driver_address_line2: string;
  driver_address_postcode: string;
  driver_address_town: string;
  driver_address_lookup_id: string;
  driver_address_udprn: string;
  driver_date_of_birth: string;
  driver_email: string;
  driver_first_name: string;
  driver_last_name: string;
  driver_phone_number: string;
  driver_title: string;
  driver_occupation_type: string;
  driver_occupation: string;
  is_driver_owner: boolean;
  excess: number;
  same_name_confirmed: boolean;
  privacy_accepted: boolean;
  licence_country: string;
  cassie_licence_type: string;
  licence_type: string;
  licence_number: string;
  full_provisional_confirmed: boolean;
  full_provisional_date_confirmed: boolean;
  user_relationship: string;
  ncb: boolean;
  ncb_years?: number;
  owner_email: string;
  owner_first_name: string;
  owner_last_name: string;
  owner_date_of_birth: string;
  document_delivery_preference: boolean;
  reason_for_cover: string;
  expected_mileage: number;
  overnight_location: string;
  alternate_vehicle: string;
}

interface QuoteCopyResponse {
  message: string;
  new_quote: NewQuote;
}

type CarRegResponse = {
  car_registration_number: string;
  policy_uuid: string;
  car_make: string;
  car_model: string;
};

export type PreviousCustomerCar = {
  registrationNumber: string;
  policyUuid: string;
  make: string;
  model: string;
};

/**
 * {@link https://redux-toolkit.js.org/rtk-query/overview#create-an-api-slice | RTK Query Overview > Basic Usage}
 *
 * {@link https://redux-toolkit.js.org/rtk-query/api/createApi | RTK Query > API Reference > createApi}
 *
 * {@link https://redux-toolkit.js.org/rtk-query/usage-with-typescript#createapi | Usage with Typescript}
 * */
export const quoteApi = createApi({
  reducerPath: 'quoteApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_CSI_BASE_URL}/`,
    prepareHeaders: async (headers) => {
      const authorization = await retrieveCognitoBearerToken();
      if (typeof authorization === 'string') {
        headers.set('Authorization', authorization);
      }

      const csrfTokenValue = getCookie('csrftoken');
      headers.set('CSRF', csrfTokenValue);
      headers.set('Content-Type', 'application/json');
      headers.set('accept', 'application/json');
      return headers;
    },
  }),
  endpoints: (builder) => ({
    /** {@link https://redux-toolkit.js.org/rtk-query/usage/mutations | Using RTK Query > Mutations} */
    createReferral: builder.mutation({
      query: ({ quoteUuid, buyapowaEncryptedValue }) => ({
        url: `api/quotes/${quoteUuid}/referrals`,
        method: 'POST',
        body: { bp_e: buyapowaEncryptedValue },
      }),
    }),
    checkout: builder.query({
      query: (quoteUuid) => {
        if (!quoteUuid) {
          throw new Error(`quoteUuid is required`);
        }

        return {
          url: `tempcover/v1/policies/${quoteUuid}/checkout/`,
          method: 'GET',
          responseHandler: 'json',
        };
      },
      transformResponse: (response: CheckoutResponse) => {
        return {
          driverEmail: response.driver_email,
          driverFirstName: response.driver_first_name,
          driverLastName: response.driver_last_name,
          isFirstPurchase: response.is_first_purchase,
          isSubscription: response.is_subscription,
          price: response.price,
          productType: response.product_type,
          referenceNumber: response.reference_number,
          utmSource: response.utm_source,
          commissionGroup: response.commission_group,
          durationStart: response.duration_start,
          durationEnd: response.duration_end,
          subscriptionReferenceNumber: response.subscription_reference_number,
          numberOfPolicies: response.number_of_policies,
        };
      },
    }),
    quoteCopy: builder.query({
      query: (quoteUuid) => {
        if (!quoteUuid) {
          throw new Error(`quoteUuid is required`);
        }
        return {
          url: `api/quotes/${quoteUuid}/repurchase`,
          method: 'POST',
          responseHandler: 'json',
        };
      },
      transformResponse: (response: QuoteCopyResponse) => {
        const transformedResponse = quotesTransformer.apiToRedux(response.new_quote);
        return { ...transformedResponse, uuid: response.new_quote.uuid };
      },
    }),
    getRecentCustomerCarRegs: builder.query({
      query: () => ({
        url: 'api/quotes/repurchase/',
        method: 'GET',
        responseHandler: 'json',
      }),
      transformResponse: (response: CarRegResponse[]): PreviousCustomerCar[] => {
        return response.map((car) => ({
          registrationNumber: car.car_registration_number,
          policyUuid: car.policy_uuid,
          make: car.car_make,
          model: car.car_model,
        }));
      },
    }),
  }),
});

// These hooks are the primary way to use RTK query.
// You just import them and use them in components,
// and use their `data`, `error`, `isLoading` properties directly.
// See: https://redux-toolkit.js.org/rtk-query/overview#use-hooks-in-components }
export const {
  useCreateReferralMutation,
  useCheckoutQuery,
  useQuoteCopyQuery,
  useGetRecentCustomerCarRegsQuery,
} = quoteApi;
