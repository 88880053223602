import { PaymentMethod } from '@utils/types';
import { QuoteActions } from './actions';

import {
  ProductActions,
  SET_PRODUCT,
  SET_SUBSCRIPTION,
  SET_PAYMENT_METHOD,
} from './product.actions';
import { FastRepurchaseActions, types as fastRepurchasetypes } from './fastRepurchase.actions';

function urlProductType(): string {
  const urlParams = new URLSearchParams(window.location.search);
  const product = urlParams.get('product');
  return product === 'newdriver' ? product : 'csi';
}

interface UserState {
  productType: string;
  isSubscription: boolean;
  paymentMethod: PaymentMethod | null;
  previousProductType?: string;
}

export const initialState: UserState = {
  // initialise as CSI due to uk manual licence being defaulted to true
  productType: urlProductType(),
  previousProductType: undefined,
  isSubscription: false,
  paymentMethod: null,
};

export type ProductActionTypes = ProductActions | QuoteActions | FastRepurchaseActions;

export default function user(
  state: UserState = initialState,
  action = {} as ProductActionTypes,
): UserState {
  switch (action.type) {
    case SET_PRODUCT:
      return {
        ...state,
        productType: action.product,
        previousProductType:
          action.product !== state.productType ? state.productType : state.previousProductType,
      };
    case SET_SUBSCRIPTION:
      return {
        ...state,
        isSubscription: action.isSubscription,
      };
    case SET_PAYMENT_METHOD:
      return {
        ...state,
        paymentMethod: action.paymentMethod,
        isSubscription: action.paymentMethod === PaymentMethod.subscription,
      };
    case fastRepurchasetypes.SET_FAST_REPURCHASE_DATA:
      return {
        ...state,
        ...action.data.product,
      };
    default:
      return state;
  }
}
