import React, { useState, useEffect } from 'react';
import { Location } from 'history';
import styled from 'styled-components';

import { InjectedFormProps } from 'redux-form';
import { useSelector } from 'react-redux';

import QuoteStep from '@templates/QuoteStep';
import Group from '@atoms/layout/form/Group';
import OwnerDetailsModal from '@organisms/modals/OwnerDetailsModal';
import CarSearch from '@organisms/blocks/CarSearch';
import OwnerSummary from '@organisms/blocks/OwnerSummary';
import { RootState } from '@redux/reducer';

import { FieldContainer } from '@rentecarlo/component-library';

import { logNoMainInsuranceEvent, pushReasonForCover } from '@services/ecommerceTracking';
import { Car } from './CarDetails.container';

import ReasonForCoverField from './fields/ReasonForCoverField';
import AIQuestionField from './fields/AIQuestionField';

type LocationType = Location & {
  state: {
    scrollToOwner?: boolean;
  };
};

interface Props {
  productType: string;
  tempcoverReason: string;
  isNewCar: boolean;
  initialValues: {
    tempcoverReason: string;
    relationship: string;
  };
  openTempcoverReasonModal: (e: React.SyntheticEvent) => void;
  closeTempcoverReasonModal: () => void;
  tempcoverModalOpen: boolean;
  openRelationshipModal: (e: React.SyntheticEvent) => void;
  closeRelationshipModal: () => void;
  relationshipModalOpen: boolean;
  changeToCsi: () => void;
  setHasInsurance: (value: string) => void;
  alternativeInsurers: Array<{ name: string; value: string }>;
  otherInsuranceSelected: boolean;
  getInsurersName: string;
  hasInsurance: string;
  toggleTempcoverPersonalVehicle: () => void;
  resetMainInsuranceQuestion: () => void;
  valid: boolean;
  car: Car;
  quoteInfo: () => void;
  submitToQuote: () => void;
  isLoggedIn: boolean;
  termsOfCoverConfirmed: boolean;
  toggleTermsOfCover: () => void;
  submitOwnerDetails: () => void;
  initaliseOwnerDetailsForm: () => void;
  resetPageState: () => void;
  ownerFirstName: string;
  ownerSurname: string;
  ownerBirthdate: string;
  ownerEmail: string;
  ownerRelationship: string;
  hasOwnerDetails: boolean;
  formErrors: Record<string, unknown>;
  form: Record<string, unknown>;
  submitFailed: boolean;
  syncErrors: Record<string, unknown>;
  location: LocationType;
  isDriverOwner: boolean;
  isSubscription: boolean;
  selectedRelationship: string;
  isQuoteForMyself: boolean;
}

export const SaveCarContainer = styled.div`
  margin-top: -44px;
  margin-bottom: 30px;
`;

const CarDetails: React.FC<Props & InjectedFormProps> = ({
  initialValues,
  formErrors,
  isDriverOwner,
  productType,
  isNewCar,
  car,
  hasOwnerDetails,
  selectedRelationship,
  submitToQuote,
  isLoggedIn,
  tempcoverModalOpen,
  closeTempcoverReasonModal,
  relationshipModalOpen,
  openTempcoverReasonModal,
  valid,
  handleSubmit,
  submitOwnerDetails,
  form,
  submitFailed,
  tempcoverReason,
  setHasInsurance,
  alternativeInsurers,
  getInsurersName,
  toggleTempcoverPersonalVehicle,
  hasInsurance,
  otherInsuranceSelected,
  isSubscription,
  changeToCsi,
  termsOfCoverConfirmed,
  toggleTermsOfCover,
  initaliseOwnerDetailsForm,
  resetPageState,
  location,
  isQuoteForMyself,
}) => {
  const [showOwnerModal, setShowOwnerModal] = useState(false);
  const [submitError, setSubmitError] = useState(false);
  const [ownerDetailsError, setOwnerDetailsError] = useState('');
  const fullState = useSelector((state: RootState) => state);
  const stepTitle = 'Car details';

  useEffect(() => {
    resetPageState();
    if (termsOfCoverConfirmed) {
      toggleTermsOfCover();
    }

    initaliseOwnerDetailsForm();

    const { state } = location;
    if (state && state.scrollToOwner) {
      const scrollElement = document.getElementById('owner-details-section') || document.body;
      const scrollLocation = scrollElement.getBoundingClientRect().y;
      window.scrollTo(0, scrollLocation);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const ownerDetailError = selectedRelationship === null && !isDriverOwner ? 'Required' : '';

    setOwnerDetailsError(ownerDetailError);
  }, [selectedRelationship, isDriverOwner]);

  const getFormErrors = (): Record<string, unknown> => {
    let mergedFormErrors = {};

    if (formErrors && ownerDetailsError) {
      mergedFormErrors = { ...formErrors, ownerDetails: ownerDetailsError };
    } else {
      mergedFormErrors = formErrors || { ownerDetails: ownerDetailsError };
    }

    return mergedFormErrors;
  };

  const showInsuranceErrors = (): boolean => {
    let shouldShowError = false;

    if (
      (submitFailed || submitError) &&
      formErrors &&
      formErrors.hasInsurance &&
      formErrors.hasInsurance !== 'invalid_choice_right'
    ) {
      shouldShowError = !!(
        submitFailed &&
        formErrors &&
        (formErrors.hasInsurance || formErrors.mainInsurer)
      );
    } else if ((submitFailed || submitError) && formErrors && formErrors.isNamedDriver) {
      shouldShowError = !!(submitFailed && formErrors && formErrors.isNamedDriver);
    } else if ((submitFailed || submitError) && formErrors && formErrors.mainInsurer) {
      shouldShowError = !!(submitFailed && formErrors && formErrors.mainInsurer);
    } else if (
      formErrors &&
      formErrors.hasInsurance &&
      formErrors.hasInsurance === 'invalid_choice_right'
    ) {
      shouldShowError = !!(formErrors && formErrors.hasInsurance);
    }

    return shouldShowError;
  };

  const errorMessageToShowHasInsurance = (): string => {
    let errorMessage = '';

    if (
      formErrors &&
      formErrors.hasInsurance &&
      formErrors.hasInsurance !== 'invalid_choice_right'
    ) {
      errorMessage = formErrors.hasInsurance;
    } else if (formErrors && formErrors.mainInsurer) {
      errorMessage = formErrors.mainInsurer;
    } else if (formErrors && formErrors.isNamedDriver) {
      errorMessage = formErrors.isNamedDriver;
    } else if (
      formErrors &&
      formErrors.hasInsurance &&
      formErrors.hasInsurance === 'invalid_choice_right'
    ) {
      errorMessage = 'This cover is only valid alongside a main insurance policy';
    }

    return errorMessage;
  };

  const logInsuranceSelectionEventIfNecessary = (value: string): void => {
    if (productType === 'ldp' && value === 'false' && isDriverOwner) {
      logNoMainInsuranceEvent();
    }
  };

  const validateNextButton = (): boolean => {
    if (isDriverOwner) {
      return !!car;
    }

    return !!car && hasOwnerDetails;
  };

  const toNextStep = (values: Record<string, unknown>): void => {
    if (!ownerDetailsError) {
      submitToQuote(values, car, isLoggedIn, productType, isDriverOwner);
    } else {
      setSubmitError(true);
    }
  };

  return (
    <QuoteStep
      id='car-component-quoteStep'
      nextAction={handleSubmit((values: Record<string, string>) => {
        toNextStep(values);
      })}
      stepTitle={stepTitle}
      stepSubtitle={`Give this a once over to check we've got everything right about the car ${
        isQuoteForMyself ? 'you' : 'they'
      } want to cover.`}
      height={tempcoverModalOpen || relationshipModalOpen ? '1600' : ''}
      formErrors={getFormErrors()}
      form={form}
      submitError={(submitFailed || submitError) && (!valid || ownerDetailsError)}
      showButton={validateNextButton()}
      triggerGAEvent={() => pushReasonForCover(fullState, stepTitle)}
    >
      <Group>
        <CarSearch submitFailed={submitFailed || submitError} isQuoteForMyself={isQuoteForMyself} />
      </Group>
      {car && (
        <>
          <AIQuestionField
            showErrors={showInsuranceErrors()}
            error={errorMessageToShowHasInsurance()}
            onSetHasInsurance={(value: string): void => {
              setHasInsurance(value);
              logInsuranceSelectionEventIfNecessary(value);
            }}
            onSetTempcoverPersonalVehicle={toggleTempcoverPersonalVehicle}
            alternativeInsurers={alternativeInsurers}
            formErrors={formErrors}
            getInsurersName={getInsurersName}
            hasInsurance={hasInsurance}
            isDriverOwner={isDriverOwner}
            otherInsuranceSelected={otherInsuranceSelected}
            productType={productType}
            isQuoteForMyself={isQuoteForMyself}
          />
          {productType === 'tc' && !isSubscription && (
            <ReasonForCoverField
              changeToCsi={changeToCsi}
              closeTempcoverReasonModal={closeTempcoverReasonModal}
              showError={submitFailed && formErrors && !!formErrors.tempcoverReason}
              error={formErrors && (formErrors.tempcoverReason as string)}
              initialValues={initialValues}
              isNewCar={isNewCar}
              openTempcoverReasonModal={openTempcoverReasonModal}
              productType={productType}
              submitFailed={submitFailed}
              tempcoverModalOpen={tempcoverModalOpen}
              tempcoverReason={tempcoverReason}
              isQuoteForMyself={isQuoteForMyself}
            />
          )}
        </>
      )}
      {!isDriverOwner && !!car && (
        <div id='owner-details-section' data-testid='owner-details-section'>
          <Group>
            <FieldContainer
              id='car-title-ownerDetails'
              showErrorContainer={(submitFailed || submitError) && ownerDetailsError}
              showError={(submitFailed || submitError) && ownerDetailsError}
              error={ownerDetailsError}
            >
              <OwnerSummary setOwnerModalVisibility={(): void => setShowOwnerModal(true)} />
            </FieldContainer>
          </Group>

          <OwnerDetailsModal
            show={showOwnerModal}
            close={(): void => setShowOwnerModal(false)}
            submitOwnerDetails={submitOwnerDetails}
          />
        </div>
      )}
    </QuoteStep>
  );
};

export default CarDetails;
