import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { RootState } from '@redux/reducer';
import { LicenceTypes } from '@reducer/quote/licence';
import { updateLicenceAndGoTo } from '@redux/reducer/quote/licence.actions';

import QuoteStep from '@templates/QuoteStep';

import FullLicence from './assets/FullLicence.png';
import ProvLicence from './assets/ProvLicence.png';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Image = styled.img.attrs({
  alt: 'Update your licence',
})`
  max-width: 270px;
`;

const Text = styled.p`
  color: ${({ theme }) => theme.textPrimary};
  font-family: Proxima-Soft;
  font-size: 16px;
  line-height: 26px;
  font-weight: 700;
  text-align: center;

  margin: 24px 32px 32px;
`;

interface LocationState {
  destination: string;
  checkProductUw: boolean;
}

const LicenceSwitch: React.FC = () => {
  const location = useLocation<LocationState>();
  const dispatch = useDispatch();

  const redirectPath = location.state ? location.state.destination : '/start';
  const checkProductUw = location.state ? location.state.checkProductUw : false;

  const hasPassed: boolean | null = useSelector(
    (state: RootState) => state.quote.drivingTest.hasPassed,
  );
  const isLoggedIn = useSelector((state: RootState) => state.account.login.loggedIn);

  const newLicenceType = hasPassed === false ? LicenceTypes.uk_prov : LicenceTypes.uk_manual;

  return (
    <QuoteStep
      id='licenceSwitch-component-quoteStep'
      stepTitle={
        newLicenceType === LicenceTypes.uk_prov || !isLoggedIn ? 'Hang on...' : 'Congratulations!'
      }
      stepSubtitle={
        newLicenceType === LicenceTypes.uk_prov
          ? 'You’ve been quoting for full licence insurance but it looks like you haven’t passed your test yet.  To continue, please switch to learner driver insurance and answer any outstanding questions.'
          : 'It looks like you passed your driving test since you last purchased with us! To continue, please switch to full licence insurance and answer any outstanding questions.'
      }
      showButton
      nextLabel={
        newLicenceType === LicenceTypes.uk_prov
          ? 'Switch to learner driver insurance'
          : 'Switch to full licence insurance'
      }
      nextAction={() => {
        dispatch(updateLicenceAndGoTo(newLicenceType, redirectPath, checkProductUw));
      }}
    >
      <Container>
        <Image src={newLicenceType === LicenceTypes.uk_prov ? ProvLicence : FullLicence} />
        <Text id='licenceSwitch-text'>
          We’ve updated your quote to show prices for{' '}
          {hasPassed === false ? 'learner driver insurance' : 'full licence insurance'}
        </Text>
      </Container>
    </QuoteStep>
  );
};

export default LicenceSwitch;
