import { types as saveTypes, SaveActions } from './save.actions';
import { types as quoteTypes, QuoteActions } from './actions';
import { types as loginTypes, LoginActiontypes } from '../account/login.actions';

import {
  ImportantInformationActions,
  types as importantInformationTypes,
} from './importantInformation.actions';
import { FastRepurchaseActions, types as fastRepurchasetypes } from './fastRepurchase.actions';

export interface ImportantInformation {
  excessSelected: number | null;
  finalPricesByExcess: [];
  showUnavailableExcess: boolean;
}

// Reducer
export const initialState: ImportantInformation = {
  excessSelected: null,
  finalPricesByExcess: [],
  showUnavailableExcess: false,
};

export type ImportantInformationActionTypes =
  | ImportantInformationActions
  | QuoteActions
  | SaveActions
  | LoginActiontypes
  | FastRepurchaseActions;

export default function importantInformation(
  state: ImportantInformation = initialState,
  action = {} as ImportantInformationActionTypes,
): ImportantInformation {
  switch (action.type) {
    case importantInformationTypes.SELECT_VARIABLE_EXCESS:
      return {
        ...state,
        excessSelected: action.excess,
      };
    case saveTypes.SAVE_QUOTE_SUCCESS:
      // saves final_prices_by_excess on important-information
      // unless it would be overwriting with an empty array
      // This needs to be done when the target is the 'important-inforation' page
      // this ensures that the state is updated with the latest excess values which will
      // be returned by pricing on the call before hitting this page
      // this information should only be updated/set on the page which displays the excess boxes
      // otherwise it should be empty
      if (
        action.meta.targetPage === 'important-information' &&
        action.data.final_prices_by_excess.length > 0
      ) {
        return {
          ...state,
          finalPricesByExcess: action.data.final_prices_by_excess,
          showUnavailableExcess: action.data.show_unavailable_excess,
        };
      }
      return state;
    case loginTypes.LOGOUT_SUCCESS:
    case quoteTypes.RESET_QUOTE:
      return {
        ...initialState,
      };
    case fastRepurchasetypes.SET_FAST_REPURCHASE_DATA:
      return {
        ...state,
        ...action.data.importantInformation,
      };
    default:
      return state;
  }
}
