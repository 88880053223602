import { UnknownAction } from 'redux';
import { SetStateFieldActionCreator, VeygoResponseError } from '@utils/types';
import { QuoteData, QuoteMeta, State } from './saveTypes';

export enum types {
  SAVE_QUOTE_REQUEST = 'SAVE_QUOTE.REQUEST',
  SAVE_QUOTE_SUCCESS = 'SAVE_QUOTE.SUCCESS',
  SAVE_QUOTE_FAILURE = 'SAVE_QUOTE.FAILURE',
  UPDATE_PRICE = 'UPDATE_PRICE',
  SET_SAVE_FIELD = 'SET_SAVE_FIELD',
}

// Actions
export const setSaveField: SetStateFieldActionCreator<State, typeof types.SET_SAVE_FIELD> = (
  field,
  value,
) => ({
  type: types.SET_SAVE_FIELD,
  field,
  value,
});

export const saveAndGoTo = (
  page: string,
  checkProductUw = false,
  checkLicence = false,
): SaveAndGoTo => ({
  type: types.SAVE_QUOTE_REQUEST,
  page,
  checkProductUw,
  checkLicence,
});

export const saveQuote = (checkProductUw = false): SaveQuote => ({
  type: types.SAVE_QUOTE_REQUEST,
  page: undefined,
  checkProductUw,
});

export const saveQuoteSuccess = (
  data: QuoteData,
  meta: QuoteMeta = { targetPage: null },
): SaveQuoteSuccess => ({
  type: types.SAVE_QUOTE_SUCCESS,
  data,
  meta,
});

export const saveQuoteFailure = (error: SaveQuoteFailureError): SaveQuoteFailure => ({
  type: types.SAVE_QUOTE_FAILURE,
  error,
});

export const updatePrice = (price: string): UpdatePrice => ({
  type: types.UPDATE_PRICE,
  price,
});

export type SetSavefield = ReturnType<typeof setSaveField>;

export interface SaveAndGoTo extends UnknownAction {
  type: typeof types.SAVE_QUOTE_REQUEST;
  page: string;
  checkProductUw: boolean;
  checkLicence: boolean;
}

export interface SaveQuote {
  type: typeof types.SAVE_QUOTE_REQUEST;
  page: undefined;
  checkProductUw: boolean;
}

export interface SaveQuoteSuccess {
  type: typeof types.SAVE_QUOTE_SUCCESS;
  data: QuoteData;
  meta: QuoteMeta;
}

type SaveQuoteFailureError =
  | VeygoResponseError
  | {
      [key: string]: VeygoResponseError;
    };

export interface SaveQuoteFailure {
  type: typeof types.SAVE_QUOTE_FAILURE;
  error: SaveQuoteFailureError;
}

export interface UpdatePrice {
  type: typeof types.UPDATE_PRICE;
  price: string;
}

export type SaveActions =
  | SetSavefield
  | SaveAndGoTo
  | SaveQuote
  | SaveQuoteSuccess
  | SaveQuoteFailure
  | UpdatePrice;
