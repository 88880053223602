import dayjs from 'dayjs';
import { VeygoResponseError } from '@utils/types';
import { SaveActions, types } from './save.actions';
import { types as loginTypes, LoginActiontypes } from '../account/login.actions';
import { types as quoteTypes, QuoteActions } from './actions';
import deepUpdate from '../../../services/deepUpdate';
import { State } from './saveTypes';
import { types as fastRepurchasetypes, FastRepurchaseActions } from './fastRepurchase.actions';

export const initialState: State = {
  error: null,
  loading: false,
  expiry: dayjs().add(2, 'hour'),
  rejectionReasons: null,
  initialPrice: '',
  price: '',
  referenceNumber: '',
  purchased: '',
  uuid: '',
  underwriting: '',
  validDiscountCode: null,
  discountCodeInvalidated: null,
  ancillaryUuid: null,
};

function isDiscountCodeInvalidated(
  oldDiscountCodeInvalidated: boolean | null,
  newDiscountCodeInvalidated: boolean | null,
): boolean {
  return Boolean(
    oldDiscountCodeInvalidated && oldDiscountCodeInvalidated !== newDiscountCodeInvalidated,
  );
}

type SaveActionTypes = SaveActions | QuoteActions | LoginActiontypes | FastRepurchaseActions;

export default function save(state = initialState, action: SaveActionTypes): State {
  switch (action.type) {
    case types.SET_SAVE_FIELD:
      return deepUpdate(state, action.field, action.value);
    case types.SAVE_QUOTE_REQUEST:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case types.SAVE_QUOTE_SUCCESS:
      return {
        error: null,
        loading: false,
        rejectionReasons: action.data.failure_reasons,
        initialPrice: action.data.initial_price,
        price: action.data.price,
        referenceNumber: action.data.reference_number,
        purchased: action.data.purchased,
        uuid: action.data.uuid,
        underwriting: action.data.underwriting_criteria,
        validDiscountCode: action.data.valid_discount_code,
        discountCodeInvalidated: isDiscountCodeInvalidated(
          state.validDiscountCode,
          action.data.valid_discount_code,
        ),
        ancillaryUuid: action.data.ancillary_id || null,
      };
    case types.SAVE_QUOTE_FAILURE:
      // eslint-disable-next-line no-case-declarations
      const secondLevelError =
        typeof action.error.code === 'string'
          ? ''
          : (action.error &&
              (action.error[
                Object.keys(action.error).find(
                  (key) => (action.error[key] as VeygoResponseError)?.code !== undefined,
                ) as 'code'
              ] as VeygoResponseError)?.code) ??
            '';
      return {
        ...state,
        loading: false,
        error: secondLevelError || action.error.code,
      };
    case loginTypes.LOGIN_SUCCESS:
      return {
        ...state,
        error: null,
      };
    case loginTypes.LOGOUT_SUCCESS:
    case quoteTypes.RESET_QUOTE:
      return initialState;
    case types.UPDATE_PRICE:
      return {
        ...state,
        price: action.price,
      };
    case fastRepurchasetypes.SET_FAST_REPURCHASE_DATA:
      return {
        ...state,
        error: null,
        loading: false,
        uuid: action.data.uuid,
      };
    default:
      return state;
  }
}
