import React from 'react';
import { SectionTitle } from '@config/importantInformation';
import { Button, Card, Carousel, CarReg } from '@rentecarlo/component-library';
import { PreviousCustomerCar } from '@services/api/quoteRtk';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const Title = styled.h2`
  color: #364344;
  font-family: Graphie;
  font-weight: 700;
  text-align: left;
  padding-bottom: 8px;
  height: '64px';
`;

const StyledButton = styled(Button)`
  &:active {
    color: black;
    background-color: white;
  }
  &:hover {
    color: black;
    background-color: white;
  }
`;

const CarCard = ({ car }: { car: PreviousCustomerCar }) => {
  return (
    <Card>
      <div style={{ width: '330px', padding: '15px' }}>
        <Title id='quoteStart-title-carName'>{`${car.make} ${car.model}`}</Title>
        <div style={{ width: '45%', height: '32px' }}>
          <CarReg reg={car.registrationNumber} />
        </div>
        <div style={{ paddingTop: '15px' }}>
          <Link to={`/repurchase?quote_uuid=${car.policyUuid}`}>
            <StyledButton
              id='quoteStart-button-repurchase'
              backgroundColor='white'
              textColor='black'
              height={42}
              paddingVertical={8}
            >
              Buy Cover
            </StyledButton>
          </Link>
        </div>
      </div>
    </Card>
  );
};

const PreviousVehicles: React.FC<{ cars: PreviousCustomerCar[] }> = ({ cars }) => {
  return (
    <>
      <SectionTitle id='quoteStart-title-temporaryDriverPostcodeText'>
        Previous Vehicles
      </SectionTitle>
      <Carousel
        id='quoteStart-carousel-previousVehicles'
        data-testid='quoteStart-carousel-previousVehicles'
        items={cars.map((car) => (
          <CarCard car={car} />
        ))}
      />
    </>
  );
};

export default PreviousVehicles;
