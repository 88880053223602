import React, { useEffect } from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { Matrix } from '@rentecarlo/component-library';
import { AppContainer } from './components';
import { quote, Error } from './components/pages';
import { sizeGuard, ProductGuard } from './guards';

export const history = createBrowserHistory();

const redirectTo = (url) => () => {
  window.location = url;
  return <div />;
};

// Routes that don't specify a products list allow all by default
export const ROUTES = [
  {
    path: 'start',
    component: quote.Start,
  },
  {
    path: 'quote-start',
    component: quote.QuoteStart,
  },
  {
    path: 'choose-your-cover',
    products: ['csi', 'ldp', 'tc'],
    component: quote.ChooseYourCover,
  },
  {
    path: 'indicative-price',
    products: ['csi', 'ldp', 'tc'],
    component: quote.IndicativePriceContainer,
  },
  {
    path: 'car',
    component: quote.CarDetails,
  },
  {
    path: 'about-you',
    component: quote.AboutYouContainer,
  },
  {
    path: 'driver-details-summary',
    component: quote.DriverDetailsSummary,
  },
  {
    path: 'driving-profile',
    products: ['newdriver', 'tc'],
    component: quote.DrivingProfile,
  },
  {
    path: 'driving-history',
    component: quote.DrivingHistory,
  },
  {
    path: 'driving-test',
    component: quote.DrivingTest,
  },
  {
    path: 'duration-of-cover',
    component: quote.DurationOfCover,
  },
  {
    path: 'product-choice',
    products: ['newdriver', 'tc'],
    component: quote.ProductChoice,
  },
  {
    path: 'important-information',
    component: quote.ImportantInformation,
  },
  {
    path: 'final-quote',
    component: quote.FinalQuoteContainer,
  },
  {
    path: 'quote-register',
    component: quote.QuoteRegisterContainer,
  },
  {
    path: 'almost-there',
    component: quote.AlmostThereContainer,
  },
  {
    path: 'payment',
    component: quote.PaymentContainer,
  },
  {
    path: 'thank-you',
    component: quote.ThankYou,
  },
];

const LayoutGuard = (Component) => (props) => {
  return (
    <quote.QuoteContainer {...props}>
      <Component {...props} />
    </quote.QuoteContainer>
  );
};

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const router = (store) => {
  return (
    <ConnectedRouter history={history}>
      <ScrollToTop />
      <AppContainer>
        <Switch id='quote-switch'>
          {ROUTES.map((route) => (
            <Route
              key={route.path}
              path={`/${route.path}`}
              component={sizeGuard(LayoutGuard(ProductGuard(route.component, route.products)))}
            />
          ))}

          <Route
            path='/licence-change/success'
            component={sizeGuard(LayoutGuard(quote.UpgradeAccount))}
          />
          <Route
            path='/licence-change/error'
            component={sizeGuard(LayoutGuard(quote.LicenceError))}
          />
          <Route
            path='/licence-change'
            component={sizeGuard(LayoutGuard(quote.UpgradeAccountOptions))}
          />

          <Route
            path='/licence-switch/error'
            component={sizeGuard(LayoutGuard(quote.LicenceSwitchError))}
          />
          <Route path='/licence-switch' component={sizeGuard(LayoutGuard(quote.LicenceSwitch))} />

          <Route path='/already-set' component={sizeGuard(quote.AlreadySet)} />
          <Route path='/rejection' component={sizeGuard(quote.Rejection)} />
          <Route path='/processing-error' component={quote.ProcessingError} />

          <Route path='/contact-us' component={redirectTo('https://www.veygo.com/help')} />

          <Route path='/error' component={Error} />
          <Route path='/matrix' component={Matrix} />

          <Route path='/' render={() => <Redirect to='/start' />} />
        </Switch>
      </AppContainer>
    </ConnectedRouter>
  );
};

export default router;
