/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

import InfoTag from '@atoms/paragraphs/InfoTag';
import { ComponentType, useComponentId } from '@hooks';

import { InfoTagContainer, InfoText, InfoLink } from './assets/styles';

dayjs.extend(advancedFormat);
dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.tz.setDefault('Europe/London');

type Props = {
  getCurrentRestrictedPeriod: [
    {
      name?: string;
      start_date?: string;
      end_date?: string;
      min_duration?: string;
      article_link?: string;
    },
  ];
  containerStyle?: any;
  isRestrictedPeriod?: boolean;
};

const RestrictedPeriodsInfoTag: React.FC<Props> = ({
  isRestrictedPeriod,
  getCurrentRestrictedPeriod,
  containerStyle,
}) => {
  const creator = useComponentId();
  return isRestrictedPeriod ? (
    <>
      {getCurrentRestrictedPeriod.map((period) => {
        const { end_date, min_duration, article_link } = period;
        const periodEndDate = dayjs(end_date).add(1, 'day').format('Do of MMMM');
        return (
          <InfoTagContainer style={containerStyle}>
            <InfoTag id='about-you-info-tag'>
              <InfoText id={creator(ComponentType.TEXT, 'restrictedPeriodsInfo')}>
                {`Until the ${periodEndDate}, you are unable to select less than ${min_duration} hours. `}
                {article_link && (
                  <InfoText id={creator(ComponentType.TEXT, 'restrictedPeriodsArticleLink')}>
                    Want to find out more?
                    <InfoLink
                      id={creator(ComponentType.LINK, 'restrictedPeriodsArticleLink')}
                      href={article_link}
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      Click here
                    </InfoLink>
                    .
                  </InfoText>
                )}
              </InfoText>
            </InfoTag>
          </InfoTagContainer>
        );
      })}
    </>
  ) : null;
};

export default RestrictedPeriodsInfoTag;
