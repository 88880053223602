import deepUpdate from '../../../services/deepUpdate';
import { types as quoteTypes, QuoteActions } from './actions';
import { types as userTypes, UserActions } from './user.actions';

import { types as loginTypes, LoginActiontypes } from '../account/login.actions';
import { ClaimsAction, SET_CLAIMS_FIELD } from './claims.actions';
import { FastRepurchaseActions, types as fastRepurchasetypes } from './fastRepurchase.actions';

export interface State {
  hasClaims: boolean | null;
  numberOfClaims: string;
}

export const initialState: State = {
  hasClaims: null,
  numberOfClaims: '',
};

type ListenedAction =
  | LoginActiontypes
  | ClaimsAction
  | UserActions
  | QuoteActions
  | FastRepurchaseActions;

export default function quote(state: State = initialState, action: ListenedAction): State {
  switch (action.type) {
    case SET_CLAIMS_FIELD:
      return deepUpdate(state, action.field, action.value);
    case userTypes.PREFILL_OWNER:
    case loginTypes.LOGOUT_SUCCESS:
    case quoteTypes.RESET_QUOTE:
      return initialState;
    case fastRepurchasetypes.SET_FAST_REPURCHASE_DATA:
      return {
        ...state,
        ...action.data.claims,
      };
    case userTypes.PREFILL_CONNECTION:
    default:
      return state;
  }
}
