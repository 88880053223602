import React from 'react';
import styled from 'styled-components';
import dayjs, { Dayjs } from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

import fonts from '@config/fonts';
import colors from '@config/colors';

dayjs.extend(advancedFormat);
dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.tz.setDefault('Europe/London');

interface DurationSummaryContainerProps {
  isSubscription: boolean;
  subsFuturePurchaseEnabled: boolean;
}

const DurationSummaryContainer = styled.div<DurationSummaryContainerProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: ${colors.white};
  border-radius: 4px;
  margin-top: 10px;
  min-height: 36px;
  padding: 5px 0;
  cursor: ${(props) =>
    props.isSubscription && !props.subsFuturePurchaseEnabled ? 'auto' : 'pointer'};
`;

const DurationBox = styled.span`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
`;

const DurationTitle = styled.span`
  color: ${colors.easternBlue};
  weight: bold;
  font-family: ${fonts.standard};
  font-size: 12px;
  font-weight: bold;
  line-height: 14px;
`;

const DurationDateTimeWrapper = styled.span`
  text-align: center;
`;

const DurationDate = styled.span`
  font-family: ${fonts.standard};
  font-size: 14px;
  font-weight: bold;
  line-height: 17px;
  text-transform: uppercase;
  padding-right: 5px;
`;

const DurationTime = styled.span`
  font-family: ${fonts.standard};
  font-size: 14px;
  line-height: 17px;
`;

const SubsDurationTime = styled(DurationTime).attrs({
  as: 'div',
})`
  min-height: 17px;
`;

const DurationSeperator = styled.span`
  box-sizing: border-box;
  height: 24px;
  width: 1px;
  border: 0.5px solid #cbcbcb;
`;

interface Props {
  isSubscription: boolean;
  goToDurationOfCover: () => void;
  startDateTime?: Dayjs;
  endDateTime?: Dayjs;
  subsFuturePurchaseEnabled: boolean;
}

const DurationSummary: React.FC<Props> = ({
  isSubscription,
  goToDurationOfCover,
  startDateTime,
  endDateTime,
  subsFuturePurchaseEnabled,
}) => {
  const durationEndDate = endDateTime?.tz('Europe/London');
  const durationStartDate = startDateTime?.tz('Europe/London');

  const durationSummaryClick = () => {
    return !isSubscription || subsFuturePurchaseEnabled ? goToDurationOfCover() : undefined;
  };
  return (
    <DurationSummaryContainer
      id='indicativePrice-container-durationSummary'
      isSubscription={isSubscription}
      onClick={() => durationSummaryClick()}
      subsFuturePurchaseEnabled={subsFuturePurchaseEnabled}
    >
      <DurationBox id='startingDuration'>
        <DurationTitle id='indicativePrice-title-startNow'> STARTING NOW </DurationTitle>
        <DurationDateTimeWrapper>
          <DurationDate id='startingDurationDate'>
            {durationStartDate && durationStartDate.format('ddd, Do MMM')}
          </DurationDate>
          {(!isSubscription && (
            <DurationTime id='startingDurationTime'>
              {durationStartDate && durationStartDate.format('HH:mm')}
            </DurationTime>
          )) || <SubsDurationTime id='indicativePrice-text-now'>NOW</SubsDurationTime>}
        </DurationDateTimeWrapper>
      </DurationBox>
      {!isSubscription && (
        <>
          <DurationSeperator />
          <DurationBox id='endDuration'>
            <DurationTitle id='indicativePrice-title-to'>TO</DurationTitle>
            <DurationDateTimeWrapper>
              <DurationDate id='endDurationDate'>
                {durationEndDate && durationEndDate.format('ddd, Do MMM')}
              </DurationDate>
              <DurationTime id='endDurationTime'>
                {durationEndDate && durationEndDate.format('HH:mm')}
              </DurationTime>
            </DurationDateTimeWrapper>
          </DurationBox>
        </>
      )}
    </DurationSummaryContainer>
  );
};

export default DurationSummary;
