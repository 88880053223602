/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { connect } from 'react-redux';

import { Dispatch } from 'redux';
import {
  reduxForm,
  formValueSelector,
  getFormInitialValues,
  change,
  initialize,
  getFormSyncErrors,
} from 'redux-form';
import { saveAndGoTo } from '@reducer/quote/save.actions';
import { setUserField } from '@reducer/quote/user.actions';
import { setProduct } from '@reducer/quote/product.actions';
import { setOwnerFields } from '@reducer/quote/owner.actions';
import {
  ownerDetailsPageSubmitted,
  markPageAsComplete,
  markPageAsReset,
} from '@reducer/pages/actions';

import {
  loadFromLookup,
  setCarField,
  toggleHasInsurance,
  toggleTermsOfCover,
  toggleTempcoverPersonalVehicle,
} from '@reducer/quote/car.actions';
import {
  setReasonForCover,
  setOtherReasonForCover,
  setNamedDriver,
} from '@reducer/quote/tempcover.actions';
import CarDetails from './CarDetails.component';

export interface Car {
  abi_code: string;
  engine_size: number;
  make: string;
  model: string;
  transmission: string;
  year: number;
  value: number;
}

interface Owner {
  firstName: string;
  surname: string;
  birthdate: Date;
  email: string;
  relationship: string;
}

const formSelector = formValueSelector('carForm');
const initialFormSelector = getFormInitialValues('carForm');

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getInsurersName = (state: any): string => {
  let mainInsurer = formSelector(state, 'mainInsurer');
  if (mainInsurer === 'Other') {
    mainInsurer = formSelector(state, 'alternateInsurer');
  }
  return mainInsurer;
};

const checkOwnerDetails = ({
  firstName,
  surname,
  birthdate,
  email,
  relationship,
}: Owner): boolean => !!(firstName && surname && birthdate && email && relationship);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const mapStateToProps = (state: any): any => ({
  initialValues: initialFormSelector(state) || {},
  tempcoverModalOpen: state.quote.user.tempcoverModalOpen,
  tempcoverReason: formSelector(state, 'tempcoverReason'),
  relationshipModalOpen: state.quote.user.relationshipModalOpen,
  productType: state.quote.product.productType,
  alternativeInsurers: state.config.alternativeInsurers,
  otherInsuranceSelected: formSelector(state, 'mainInsurer') === 'Other',
  getInsurersName: getInsurersName(state),
  hasInsurance: state.quote.car.hasInsurance,
  car: state.lookup.car,
  isLoggedIn: state.account.login.loggedIn,
  lookupCar: state.lookup.car,
  termsOfCoverConfirmed: state.quote.car.termsOfCoverConfirmed,
  ownerFirstName: state.quote.owner.firstName,
  ownerSurname: state.quote.owner.surname,
  ownerBirthdate: state.quote.owner.birthdate,
  ownerEmail: state.quote.owner.email,
  ownerRelationship: state.quote.owner.relationship,
  hasOwnerDetails: checkOwnerDetails(state.quote.owner),
  formErrors: getFormSyncErrors('carForm')(state),
  isDriverOwner: state.quote.driver.isDriverOwner,
  isNewCar: state.quote.car.isNewCar,
  isSubscription: state.quote.product.isSubscription,
  isQuoteForMyself: state.account.customer.isQuoteForMyself,
});

interface DispatchProps {
  openTempcoverReasonModal: (e: React.SyntheticEvent) => void;
  closeTempcoverReasonModal: () => void;
  openRelationshipModal: (e: React.SyntheticEvent) => void;
  closeRelationshipModal: () => void;
  changeToCsi: () => void;
  setHasInsurance: (value: string) => void;
  toggleTempcoverPersonalVehicle: () => void;
  resetMainInsuranceQuestion: () => void;
  submitToQuote: (
    values: Record<string, unknown>,
    car: Car,
    loggedIn: boolean,
    productType: string,
    isDriverOwner: boolean,
    dispatch: Dispatch,
  ) => void;
  toggleTermsOfCover: () => void;
  submitOwnerDetails: (values: Record<string, unknown>, featureToggle: boolean) => void;
  initaliseOwnerDetailsForm: (fields: Record<string, unknown>) => void;
  resetPageState: () => void;
}

export const submitToQuote = (
  values: Record<string, unknown>,
  car: Car,
  loggedIn: boolean,
  productType: string,
  isDriverOwner: boolean,
  dispatch: Dispatch,
): void => {
  if (productType === 'tc') {
    dispatch(setReasonForCover(values.tempcoverReason));
    dispatch(setOtherReasonForCover(values.tempcoverAltReason || ''));
    dispatch(setNamedDriver(values.isNamedDriver || ''));
    dispatch(setUserField('relationship', null));
  }
  dispatch(toggleHasInsurance(values.hasInsurance));
  // If main insurer is other , fetch the value from the input field
  const insurerValue =
    values.mainInsurer === 'Other' ? values.alternateInsurer : values.mainInsurer;
  dispatch(setCarField('mainInsurer', insurerValue));
  dispatch(loadFromLookup(car));

  // If value has been manually entered, add it to the car once the car is loaded from lookup
  if (values.carValue) {
    dispatch(setCarField('value', values.carValue));
  }

  dispatch(markPageAsComplete('carDetails'));

  if (isDriverOwner && values.hasInsurance !== 'false' && values.isNamedDriver === 'true') {
    return dispatch(saveAndGoTo('/already-set'));
  }

  return dispatch(saveAndGoTo(loggedIn ? '/driver-details-summary' : '/about-you'));
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  openTempcoverReasonModal: (e: React.SyntheticEvent): void => {
    e.preventDefault();
    dispatch(setUserField('tempcoverModalOpen', true));
  },
  closeTempcoverReasonModal: (): void => dispatch(setUserField('tempcoverModalOpen', false)),
  openRelationshipModal: (e: React.SyntheticEvent): void => {
    e.preventDefault();
    dispatch(setUserField('relationshipModalOpen', true));
  },
  closeRelationshipModal: (): void => dispatch(setUserField('relationshipModalOpen', false)),
  changeToCsi: (): void => {
    dispatch(setUserField('tempcoverModalOpen', false));
    dispatch(setProduct('csi'));
  },
  setHasInsurance: (value: string): void => {
    dispatch(toggleHasInsurance(value));
    dispatch(change('carForm', 'tempcoverConfirmedIndividualOwner', null));
    dispatch(change('carForm', 'altInsuranceTradeOrFleet', null));
  },
  toggleTempcoverPersonalVehicle: (): void => dispatch(toggleTempcoverPersonalVehicle()),
  resetMainInsuranceQuestion: (): void => dispatch(toggleHasInsurance(null)),
  submitToQuote: (
    values: Record<string, unknown>,
    car: Car,
    loggedIn: boolean,
    productType: string,
    isDriverOwner: boolean,
  ): void => submitToQuote(values, car, loggedIn, productType, isDriverOwner, dispatch),
  toggleTermsOfCover: (): void => dispatch(toggleTermsOfCover()),
  submitOwnerDetails: (values, featureToggled): void => {
    const forceSaveTmp = { ...values, save: true };
    dispatch(setOwnerFields(forceSaveTmp, featureToggled));
    dispatch(ownerDetailsPageSubmitted(forceSaveTmp));
  },
  initaliseOwnerDetailsForm: (fields: Record<string, unknown>): void =>
    dispatch(initialize('ownerDetailsForm', fields)),
  resetPageState: (): void => dispatch(markPageAsReset('carDetails')),
});

const mergeProps = (
  propsFromState: any,
  propsFromDispatch: DispatchProps,
  ownProps: Record<string, unknown>,
): void => ({
  ...propsFromState,
  ...propsFromDispatch,
  ...ownProps,
  initaliseOwnerDetailsForm: (): void => {
    const {
      ownerEmail,
      ownerFirstName,
      ownerSurname,
      ownerBirthdate,
      ownerRelationship,
    } = propsFromState;
    propsFromDispatch.initaliseOwnerDetailsForm({
      firstName: ownerFirstName,
      surname: ownerSurname,
      birthdate: ownerBirthdate,
      email: ownerEmail,
      relationship: ownerRelationship,
    });
  },
  // ownProps resets the initialProps with the one in the form which hasn't initialised yet
  // We need to make it use our state over the forms default one
  initialValues: propsFromState.initialValues,
});

const CarDetailsForm = reduxForm({
  form: 'carForm',
})(CarDetails);

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(CarDetailsForm);
